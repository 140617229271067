<template>
  <section>
    <MatchinhSearch/>
    <MatchingProducts/>
  </section>
</template>

<script>
import MatchinhSearch from "@/views/hepsiburada/matching/MatchingSearch";
import MatchingProducts from "@/views/hepsiburada/matching/MatchingProducts";

export default {
  name: "MatchingHome",
  components: {MatchinhSearch, MatchingProducts},
  created() {
    this.$store.dispatch('productBrand/getAll')
  }
}
</script>

<style scoped>

</style>
