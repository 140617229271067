<template>
  <b-overlay :show="show" rounded="lg">
    <b-card>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="2">


            <v-select
                v-model="_search.status"
                label="status"
                multiple
                :options="_matchStatus"
                :reduce="statu => statu.id"
                class="select-size-sm"
            />
            <b-form-checkbox v-model="_search.barcode_match"> Barkod Eşli</b-form-checkbox>
            <b-form-checkbox v-model="_search.name_match"> Adı Eşli</b-form-checkbox>


          </b-col>
          <b-col md="4">
            <b-row>
              <b-col md="6">
                <b-form-input type="text" size="sm" v-model="_search.merchant_sku" placeholder="Pazaryeri SKU"/>
                <b-form-input type="text" size="sm" v-model="_search.product_mp_id" placeholder="Pazaryeri No ??"/>
                <b-form-input type="text" size="sm" v-model="_search.product_name" placeholder="Pazaryeri Adı"/>
              </b-col>
              <b-col md="6">
                <v-select
                    v-model="_search.suppliers"
                    label="name"
                    multiple
                    :options="_suppliers"
                    :reduce="item => item.id"
                    class="select-size-sm"
                    placeholder="Tedarikçi"
                    :closeOnSelect="false"
                >
                </v-select>

                <vue-autosuggest
                    :suggestions="filteredBrands"
                    :limit="limit"
                    :input-props="{id:'autosuggest__input',class:'form-control form-control-sm', placeholder:'Marka Seçiniz!'}"
                    @input="onInputChange"
                    @selected="selectHandler"
                >
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                  </template>
                </vue-autosuggest>

                <b-badge v-for="(brand,key) in _search.brands" :key="brand.id"
                         @click="deleteBrand(key)">{{ brand.name }}
                </b-badge>


              </b-col>
            </b-row>

          </b-col>
          <b-col md="4">
            <b-row>
              <b-col md="6">
                <b-form-input type="text" size="sm" v-model="_search.product.barcode" placeholder="Ürün Barkod"/>
                <b-form-input type="text" size="sm" v-model="_search.product.id" placeholder="Ürün No"/>
                <b-form-input type="text" size="sm" v-model="_search.product.name" placeholder="Ürün Adı"/>
              </b-col>

              <b-col md="6">
                <b-form-input type="number" size="sm" v-model="_search.product.stock" placeholder="Stok (Büyük)"/>
                <b-form-input type="number" size="sm" v-model="_search.limit" placeholder="Limit Giriniz"/>

                <b-row>
                  <b-col class="demo-inline-spacing" style="margin-top: 0px">
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isMultiple"
                        plain
                        name="isMultiple"
                        value="all"
                    > Tümü (Ür.)
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isMultiple"
                        plain
                        name="isMultiple"
                        value="single"
                    > Tekli
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isMultiple"
                        plain
                        name="isMultiple"
                        value="multi"
                    > Çoklu
                    </b-form-radio>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="demo-inline-spacing" style="margin-top: 0px">
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="all"
                    > Tümü (Eş)
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="single"
                    > Tekli
                    </b-form-radio>
                    <b-form-radio
                        class="custom-control-info"
                        style="margin-top: 0px"
                        v-model="_search.isSingleMulti"
                        plain
                        name="isSingleMulti"
                        value="multi"
                    > Çoklu
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="2">
            <b-button-group size="sm">
              <b-button variant="primary" type="submit" @click="getAll(0)">Getir</b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-form>

      <b-row>
        <b-col cols="6">
          <b-overlay :show="showMultiprocessing"></b-overlay>
          <b-row>
            <b-col cols="3" class="m-0 p-0">
              <b-form-checkbox
                  v-model="allSelectedItems"
                  :disabled="_products.length<1"
                  :class="selectClass"
                  @change="setAllSelectedItem"
              > Tümü ({{ $store.state.hbProductMatching.selectedItems.length }})
              </b-form-checkbox>
            </b-col>
            <b-col cols="3" class="m-0 p-0">
              <v-select
                  :disabled="_products.length<1"
                  v-model="_multiprocessing.select"
                  label="title"
                  :options="[{value:'selected',title:'Seçilenler'},{value:'all',title:'Tümü'}]"
                  :reduce="item => item.value"
                  class="select-size-sm"/>
            </b-col>
            <b-col cols="3" class="m-0 p-0">
              <v-select
                  :disabled="_products.length<1"
                  v-model="_multiprocessing.process"
                  label="title"
                  :options="[{id:2,title:'Onayla'},{id:5,title:'Ertele'},{id:4,title:'Reddet'}]"
                  :reduce="item => item.id"
                  class="select-size-sm"/>
            </b-col>
            <b-col cols="3" class="m-0 p-0">
              <b-button-group>
                <b-button
                    variant="primary"
                    size="sm"
                    :disabled="_multiprocessing.select==='' || _multiprocessing.process===''"
                    @click="applyToAll"
                >Uygula
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6"></b-col>


      </b-row>

      <b-row>
        <b-col md="2">
          <b-button-group size="sm">
            <b-button variant="info" :disabled="_products.length<1" @click="allProductsMatch(true)">Tümünü Seç
            </b-button>
            <b-button variant="info" :disabled="_products.length<1" @click="allProductsMatch(false)">Tümünü Kaldır
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <Pagination :pagination="_pagination" @pageClick="getAll"/>
    </b-card>
  </b-overlay>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from "@/components/Pagination";
import {VueAutosuggest} from 'vue-autosuggest'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import axios from "@/libs/axios";

export default {
  name: "MatchinhSearch",
  data: () => ({
    show: false,
    filteredBrands: [],
    limit: 20,
    showMultiprocessing: false,
  }),
  components: {Pagination, VueAutosuggest, vSelect},
  computed: {
    ...mapGetters('hbProductMatching', ["_products", "_search", "_pagination", "_multiprocessing"]),
    ...mapGetters('matchStatus', ['_matchStatus']),
    ...mapGetters('productBrand', ["_brands"]),
    ...mapGetters("supplier", ["_suppliers"]),
    selectClass() {
      if (this.$store.state.hbProductMatching.selectedItems.length >= this._products.length)
        return 'custom-control-primary'
      return 'custom-control-secondary'
    },
    allSelectedItems: {
      get() {
        return this._products.length > 0 &&
            this.$store.state.hbProductMatching.selectedItems.length > 0
      },
      set() {
      }
    }
  },
  methods: {
    applyToAll() {
      this.showMultiprocessing = true;
      if (this._multiprocessing.select === 'selected') {
        let data = this.$store.state.hbProductMatching.selectedItems.map(item => {
          var item2 = item.split("-");
          return {
            hb_product_id: item2[0],
            product_id: item2[1],
            status: this._multiprocessing.process
          }
        })
        this.$store.dispatch('hbProductMatching/setProductsPivotStatus', data)
            .then(() => {
              this.$store.state.hbProductMatching.selectedItems = [];
              this.showMultiprocessing = false;
              this.getAll(1);
            })
      }
    },
    setAllSelectedItem(event) {
      let selected = [];
      if (event) {
        let products = this._products.filter(hbProduct => {
          if (hbProduct.products2.length === 1) {
            return true;
          }
        })
        selected = products.map(hbProduct => {
          if (hbProduct.products2.length === 1) {
            return `${hbProduct.id}-${hbProduct.products2[0].id}`;
          }
        })
      } else {
        selected = [];
      }

      this.$store.state.hbProductMatching.selectedItems = selected;
    },
    getAll(page) {
      this.$store.state.hbProductMatching.selectedItems = [];
      this.show = true
      this._search.page = page;
      this.$store.dispatch('hbProductMatching/getProducts')
          .then(res => {
            this.show = false;
          })
          .catch(() => {
            this.show = false;
          })
    },
    onInputChange(text) {
      if (text === '' || text === undefined) return
      const data = this._brands.filter(brand => brand.name.toLowerCase().indexOf(text.toLowerCase()) > -1).splice(0, this.limit);
      this.filteredBrands = [{data}]
    },
    selectHandler(option) {
      this.$store.state.hbProductMatching.search.brands.push(option.item);
    },
    deleteBrand(key) {
      this.$store.commit('hbProductMatching/deleteBrand', key)
    },
    allProductsMatch(match) {
      this._products.forEach(hbProduct => {
        hbProduct.products2.forEach(product => {
          product.matching = match
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
