<template>
  <b-card class="shadow border border-secondary rounded">
    <b-row>

      <b-col md="1">
        <b-form-checkbox
            v-model="$store.state.hbProductMatching.selectedItems"
            :value="`${hbProduct.id}-${product.id}`"
        > Seç {{ hbProduct.id }} -{{ product.id }}
        </b-form-checkbox>

      </b-col>

      <b-col md="3">
        <b-row>
          <b-col md="6" class="imageContainer m-0 p-0">
            <div v-if="hbProduct.images">
              <div class="clearfix" v-if="hbProduct.images[0]" @click="showImages">
                <b-img :src="hbProduct.images[0].url" width="150"/>
              </div>
            </div>
            <div v-else>
              <div v-if="hbDetail">
                <div v-if="hbDetail.images">
                  <div class="clearfix" v-if="hbDetail.images[0]" @click="showImages">
                    <b-img :src="hbDetail.images[0].url" width="150"/>
                  </div>
                </div>
              </div>
            </div>
          </b-col>

          <b-col md="6" class="imageContainer m-0 p-0">
            <div v-if="product.images">
              <div class="clearfix" v-if="product.images[0]" @click="showImages">
                <b-img :src="'https://api.kitapfirsatlari.com/' + product.images[0].url" width="150"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>


      <b-col md="3">
        <a :href="'https://www.hepsiburada.com/w-p-'+hbProduct.merchant_sku" target="_blank">
          <feather-icon icon="ExternalLinkIcon"/>
        </a> {{ hbProduct.product_mp_id }}
        <b-badge variant="info" @click="productMatching">Ürün No Ekle</b-badge>
        <br>

        <br>
        <b-badge :variant="hbProduct.merchant_sku==product.barcode?'success':''">
          {{ hbProduct.merchant_sku }}
        </b-badge>
        <b-badge :variant="hbProduct.merchant_barcode==product.barcode?'success':''">
          {{ hbProduct.merchant_barcode }}
        </b-badge>

        <br>
        <b-badge v-if="hbDetail">{{ hbDetail.brand }}</b-badge>
        <b-badge v-else>-</b-badge>
        <br>

        <b-badge v-if="hbDetail">{{ hbDetail.catalogName }}</b-badge>
        <b-badge v-else>-</b-badge>
        <br>

        <div style="word-wrap:break-word;"
             :class="hbProduct.product_name==product.name?'bg-success text-white':'bg-secondary text-white'">
          {{ hbProduct.product_name }}
        </div>

        <b-button-group>
          <div v-if="hbProduct.buybox_list">
            <a
                v-for="(list,lIndex) in JSON.parse(hbProduct.buybox_list.list)"
                :key="lIndex"
                class="bg-info text-white mr-1"
            >
              <b>{{ list.merchantName }} {{ list.discountedPrice }} {{ list.currency }}</b>
            </a>
          </div>
        </b-button-group>
      </b-col>

      <b-col md="3">
        <a href="#" target="_blank">
          <feather-icon icon="ExternalLinkIcon"/>
        </a> {{ product.id }}
        <b-badge variant="info" @click="hbMatching">HB SKU Ekle</b-badge>
        <br>

        <br>
        <b-badge :variant="hbProduct.merchant_barcode==product.barcode?'success':''">
          {{ product.barcode }}
        </b-badge>
        <br>
        <b-badge v-if="product.brand">{{ product.brand.name }}</b-badge>
        <b-badge v-else-if="product.publishers[0]">
          {{ product.publishers[0].publisher_name }}
        </b-badge>
        <b-badge v-else>-</b-badge>
        <br>

        <b-badge v-if="product.categories[0]">{{ product.categories[0].name }}</b-badge>
        <b-badge v-else>-</b-badge>
        <br>

        <div style="word-wrap:break-word;"
             :class="hbProduct.product_name==product.name?'bg-success text-white':'bg-secondary text-white'">
          {{ product.name }}
        </div>

        <b-button-group size="sm">
          <a
              v-for="supplierProduct in product.supplier_products"
              :key="supplierProduct.id"
              class="bg-info text-white mr-1"
              target="_blank"
              :href="supplierProduct.url"
          >
            <b>
              {{ supplier_name(supplierProduct.supplier_id) }} {{ supplierProduct.stock }} Ad. {{
                supplierProduct.price
              }}
              {{ supplierProduct.currency }}
            </b>
          </a>
        </b-button-group>
      </b-col>

      <b-col>
        <div>
          <b-form-checkbox v-model="product.matching" class="custom-control-success" name="check-button" switch/>
        </div>
        <b-badge class="mt-1" :variant="matching_status_color(product.pivot.status)">
          {{ matching_status(product.pivot.status) }}
        </b-badge>
        <br>
        <b-button-group class="mt-1">
          <b-button @click="toSendStatus(2)" size="sm" variant="success"> Onayla</b-button>
          <b-button @click="toSendStatus(5)" size="sm" variant="warning"> Ertele</b-button>
          <b-button @click="toSendStatus(4)" size="sm" variant="danger"> Reddet</b-button>
        </b-button-group>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import supplierMixins from "@/mixins/supplierMixins";
import matchingStatusMixins from "@/mixins/matchingStatusMixins";

export default {
  name: "MatchingProduct",
  mixins: [supplierMixins, matchingStatusMixins],
  props: {
    hbProduct: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    hbDetail() {
      if (this.hbProduct.detail) {
        return JSON.parse(this.hbProduct.detail.detail);
      }
      return {}
    }
  },
  methods: {

    showImages() {

    },
    productMatching() {
      this.$swal({
        title: 'Ürün No Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (productId) => {
          if (!productId) return null
          return this.$store.dispatch('product/addHbMatchingProduct', {productId, sku: this.hbProduct.merchant_sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    hbMatching() {
      this.$swal({
        title: 'Pazaryeri SKU Girin',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        confirmButtonText: 'Ekle',
        showLoaderOnConfirm: true,
        preConfirm: (sku) => {
          if (!sku) return null
          return this.$store.dispatch('product/addHbMatchingProduct', {productId: this.product.id, sku})
              .then(res => {
                return res;
              })
        },
      })
          .then(result => {
            if (result.value) {
              this.$swal({
                title: result.value.message,
                icon: result.value.status,
              })
            }
          })
    },
    toSendStatus(statusId) {
      this.product.matching = false;
      let ids = [{
        hb_product_id: this.hbProduct.id,
        product_id: this.product.id,
        status: statusId
      }]
      this.$store.dispatch('hbProductMatching/setProductsPivotStatus', ids)
          .then(() => {
            let idNo = this.hbProduct.id + '-' + this.product.id;
            let index = this.$store.state.hbProductMatching.selectedItems.findIndex(id => id === idNo)
            this.$store.state.hbProductMatching.selectedItems.splice(index, 1);
          })
    }
  }
}
</script>

<style scoped>

</style>
