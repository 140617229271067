<template>
  <section>
    <b-card v-for="(hbProduct,HbIndex) in _products" :key="HbIndex">
      <MatchingProduct
          v-for="(product,pIndex) in hbProduct.products2"
          :key="pIndex"
          :hbProduct="hbProduct"
          :product="product"
      />
    </b-card>

    <b-card>
      <b-button-group>
        <b-button title="Seçili olanları eşleştir" @click="setSelectedMatching"
                  variant="success">Eşleştir
        </b-button>
      </b-button-group>
    </b-card>

  </section>
</template>

<script>
import {mapGetters} from "vuex";
import MatchingProduct from "@/views/hepsiburada/matching/MatchingProduct";

export default {
  name: "MatchingProducts",
  components: {MatchingProduct},
  computed: {
    ...mapGetters('hbProductMatching', ["_products"])
  },
  methods: {
    setSelectedMatching() {
      let ids = [];
      this._products.forEach(hbProduct => {
        hbProduct.products2.forEach(product => {
          if (product.matching) {
            ids.push({
              hb_product_id: hbProduct.id,
              product_id: product.id,
              status: 2
            })
          }
        });
      })
      this.$store.dispatch('hbProductMatching/setProductsPivotStatus', ids);
    }
  },
}
</script>

<style scoped>

</style>
